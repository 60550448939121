import React, { FC, useState, useRef } from "react";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import axios from "axios";
import { BACKEND_URL } from "backendUrl";
import { Toaster, toast } from 'sonner';


export interface PageAddListing6Props {}

const PageAddListing6: FC<PageAddListing6Props> = () => {
  const [placeId, setPlaceId] = useState<string>(""); 
  const [hallName, setHallName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [advancePercentage, setAdvancePercentage] = useState<number>(0);
  const [seatingCapacity, setSeatingCapacity] = useState<number>(0);
  const [description, setDescription] = useState<string>("");
  const [indoor, setIndoor] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedVideos, setSelectedVideos] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [hallFixedPrice, setHallFixedPrice] = useState<boolean>(false);
  const [hallIsPerPerson, setHallIsPerPerson] = useState<boolean>(false);
  const [hasBasePrice, setHasBasePrice] = useState<boolean>(false);
  const [basePrice, setBasePrice] = useState<number>(0);
  const [hasRange, setHasRange] = useState<boolean>(false);
  const [priceRange, setPriceRange] = useState<string>("");

  const [newAmenityName, setNewAmenityName] = useState<string>("");
  const [newAmenityIconFile, setNewAmenityIconFile] = useState<File | null>(null);
  const [amenitiesHall, setAmenitiesHall] = useState<{ name: string; icon: string }[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedImages(Array.from(event.target.files));
    }
  };

  const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedVideos(Array.from(event.target.files));
    }
  };

  const handleAddAmenity = async () => {
    if (newAmenityName && newAmenityIconFile) {
      const formData = new FormData();
      formData.append("file", newAmenityIconFile);

      try {
        const response = await axios.post(`${BACKEND_URL}upload-image`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        const newAmenityIcon = response.data.imageUrl;

        setAmenitiesHall([...amenitiesHall, { name: newAmenityName, icon: newAmenityIcon }]);
        setNewAmenityName("");
        setNewAmenityIconFile(null);
        if (inputRef.current) {
          inputRef.current.value = "";
        }
        setError(null);
      } catch (error) {
        console.error("Error uploading amenity icon:", error);
        setError("Error uploading amenity icon. Please try again.");
      }
    } else {
      setError("Please provide both name and icon for the amenity.");
    }
  };

  const handleAddHall = async () => {
    if (!placeId || !hallName) {
      setError("Please fill out the Place ID and Hall Name fields.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      let uploadedImageUrls: string[] = [];
      let uploadedVideoUrls: string[] = [];

      // Image Upload
      if (selectedImages.length > 0) {
        const uploadImagePromises = selectedImages.map((file) => {
          const formData = new FormData();
          formData.append("file", file);
          return axios.post(`${BACKEND_URL}upload-image`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) => response.data.imageUrl);
        });
        uploadedImageUrls = await Promise.all(uploadImagePromises);
      }

      // Video Upload
      if (selectedVideos.length > 0) {
        const uploadVideoPromises = selectedVideos.map((file) => {
          const formData = new FormData();
          formData.append("file", file);
          return axios.post(`${BACKEND_URL}upload-video`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          }).then((response) => response.data.videoUrl);
        });
        uploadedVideoUrls = await Promise.all(uploadVideoPromises);
      }

      // Creating the Hall object
      const newHall = {
        name: hallName,
        price,
        advance_percentage: advancePercentage,
        seating_capacity: seatingCapacity,
        images: uploadedImageUrls,
        description,
        videos: uploadedVideoUrls,
        indoor,
        hall_fixedPrice: hallFixedPrice,
        hall_isperperson: hallIsPerPerson,
        hasBasePrice,
        basePrice,
        hasRange,
        priceRange,
        amenities_hall: amenitiesHall,
        place: placeId, // Add the placeId to the hall object
      };

      // POST request to add hall
      await axios.post(`${BACKEND_URL}places/${placeId}/add-hall`, newHall);
      toast.success("Hall added successfully!");

      // Reset the form fields
      setHallName("");
      setPrice(0);
      setAdvancePercentage(0);
      setSeatingCapacity(0);
      setSelectedImages([]);
      setSelectedVideos([]);
      setDescription("");
      setHallFixedPrice(false);
      setHallIsPerPerson(false);
      setHasBasePrice(false);
      setBasePrice(0);
      setHasRange(false);
      setPriceRange("");
      setAmenitiesHall([]);
      setPlaceId("");
      setError(null);
    } catch (error) {
      console.error("Error uploading hall data:", error);
      setError("Error adding the hall. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CommonLayout index="06" nextHref="/add-listing-7" backtHref="/add-listing-5">
      <>
      <Toaster richColors/>
        <h2 className="text-2xl font-semibold">Add Halls</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
          
          {/* Input for Place ID */}
          <FormItem label="Place ID">
            <Input
              placeholder="Enter the Place ID"
              value={placeId}
              onChange={(e) => setPlaceId(e.target.value)}
            />
          </FormItem>

          <FormItem label="Hall name">
            <Input
              placeholder="Hall name"
              value={hallName}
              onChange={(e) => setHallName(e.target.value)}
            />
          </FormItem>

          <FormItem label="Price">
            <Input
              type="number"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
            />
          </FormItem>

          <FormItem label="Advance Percentage">
            <Input
              type="number"
              placeholder="Advance Percentage"
              value={advancePercentage}
              onChange={(e) => setAdvancePercentage(Number(e.target.value))}
            />
          </FormItem>

          <FormItem label="Seating capacity">
            <Input
              type="number"
              placeholder="Seating capacity"
              value={seatingCapacity}
              onChange={(e) => setSeatingCapacity(Number(e.target.value))}
            />
          </FormItem>

          <FormItem label="Description">
            <Input
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormItem>

          <FormItem label="Indoor">
            <input
              type="checkbox"
              checked={indoor}
              onChange={(e) => setIndoor(e.target.checked)}
            />
          </FormItem>

          {/* Pricing Options */}
          <div className="flex space-x-4">
            <FormItem label="Fixed Price">
              <input
                type="checkbox"
                checked={hallFixedPrice}
                onChange={(e) => setHallFixedPrice(e.target.checked)}
              />
            </FormItem>
            <FormItem label="Per Person Pricing">
              <input
                type="checkbox"
                checked={hallIsPerPerson}
                onChange={(e) => setHallIsPerPerson(e.target.checked)}
              />
            </FormItem>
          </div>

          {/* Base Price Option */}
          <FormItem label="Has Base Price">
            <input
              type="checkbox"
              checked={hasBasePrice}
              onChange={(e) => setHasBasePrice(e.target.checked)}
            />
          </FormItem>

          {hasBasePrice && (
            <FormItem label="Base Price">
              <Input
                type="number"
                placeholder="Base Price"
                value={basePrice}
                onChange={(e) => setBasePrice(Number(e.target.value))}
              />
            </FormItem>
          )}

          {/* Price Range Option */}
          <FormItem label="Has Range">
            <input
              type="checkbox"
              checked={hasRange}
              onChange={(e) => setHasRange(e.target.checked)}
            />
          </FormItem>

          {hasRange && (
            <FormItem label="Price Range">
              <Input
                placeholder="Price Range"
                value={priceRange}
                onChange={(e) => setPriceRange(e.target.value)}
              />
            </FormItem>
          )}

          {/* Images and Videos */}
          <FormItem label="Images">
            <input type="file" multiple onChange={handleImageChange} />
            <div className="grid grid-cols-2 gap-4 mt-4">
              {selectedImages.map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  alt={`Selected ${index + 1}`}
                  className="object-cover w-full h-32 rounded-md"
                />
              ))}
            </div>
          </FormItem>

          <FormItem label="Videos">
            <input type="file" multiple onChange={handleVideoChange} />
            <div className="grid grid-cols-2 gap-4 mt-4">
              {selectedVideos.map((video, index) => (
                <video
                  key={index}
                  src={URL.createObjectURL(video)}
                  controls
                  className="object-cover w-full h-32 rounded-md"
                />
              ))}
            </div>
          </FormItem>

          {/* Amenities */}
          <FormItem label="Amenities">
            <div className="space-y-4">
              <div className="flex space-x-4 items-center">
                <textarea
                  placeholder="Amenity Name"
                  value={newAmenityName}
                  onChange={(e) => setNewAmenityName(e.target.value)}
                  rows={2}
                  className="flex-grow resize-y p-2 border border-neutral-200 rounded-md w-full"
                ></textarea>

                <input
                  type="file"
                  ref={inputRef}
                  onChange={(e) => {
                    if (e.target.files) {
                      setNewAmenityIconFile(e.target.files[0]);
                    }
                  }}
                  className="p-2 border border-neutral-200 rounded"
                />
                <button
                  onClick={handleAddAmenity}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add
                </button>
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <ul className="space-y-2">
                {amenitiesHall.map((amenity, index) => (
                  <li key={index} className="flex items-center space-x-2">
                    <img
                      src={amenity.icon}
                      alt={amenity.name}
                      width="20"
                      height="20"
                    />
                    <span>{amenity.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          </FormItem>

          {/* Add Hall Button */}
          <ButtonSecondary onClick={handleAddHall} disabled={isLoading}>
            {isLoading ? "Uploading..." : "Add Hall"}
          </ButtonSecondary>

          {isLoading && <p className="text-red-500">Uploading images and videos, please wait...</p>}
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing6;
