import React, { FC, useState, useEffect } from "react";
import { useSearchContext } from "context/search";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { Toaster, toast } from 'sonner'; // Import Toaster and toast from sonner

export interface GuestsInputProps {
  className?: string;
  useMaxGuests?: boolean; // New prop to determine if we should cap at 1000
  closeModal: () => void; // Add closeModal prop
}

const GuestsInput: FC<GuestsInputProps> = ({ className = "", useMaxGuests = false, closeModal }) => {
  const { selectedHall, guests, setGuests } = useSearchContext();
  
  // Determine the maximum guest count
  const maxGuests = useMaxGuests
    ? 1000
    : selectedHall?.seating_capacity && selectedHall.seating_capacity > 15
    ? selectedHall.seating_capacity
    : 1000; // Default to 1000 if seating_capacity is not defined or less than 15

  const [inputValue, setInputValue] = useState<number>(guests || 0);

  // Effect to reset guest count when selected hall changes
  useEffect(() => {
    // Adjust guest count if it exceeds the current hall's maximum capacity
    if (inputValue > maxGuests) {
      setInputValue(maxGuests);
      setGuests(maxGuests);
      toast.warning(`Guest count has been adjusted to the maximum allowed: ${maxGuests}`);
    }
  }, [selectedHall, maxGuests, inputValue, setGuests]);

  const handleSelectChange = (value: number) => {
    if (value <= maxGuests) {
      setInputValue(value);
      setGuests(value);
      closeModal(); // Close the modal when a guest count is selected
    } else {
      toast.error(`Maximum guest capacity is ${maxGuests}.`);
    }
  };

  const displayValue = inputValue === 0 ? "Add Guests" : `${inputValue} Guests`;

  return (
    <>
      <div className={`flex flex-col relative p-5 ${className}`}>
        <span className="mb-1 block font-semibold text-xl sm:text-2xl">
          {"Who's coming?"}
        </span>
        <div className="flex items-center mb-2">
          <div className="text-neutral-300 dark:text-neutral-400">
            <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
          </div>
          <div className="ml-2 flex-grow">
            {guests > 0 && (
              <span className="text-sm font-semibold">
                {guests} Guests
              </span>
            )}
            <span className="text-xs text-neutral-400 block">Guests</span>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="max-h-[40vh] flex-grow overflow-y-auto">
            {Array.from({ length: Math.ceil(maxGuests / 50) }, (_, i) => (i + 1) * 50)
              .filter(num => num <= maxGuests)
              .map((num) => (
                <button
                  key={num}
                  type="button"
                  className={`w-full p-2 border border-neutral-300 rounded-lg text-center mb-2 min-w-[50vw] ${
                    guests === num ? "bg-neutral-100 dark:bg-neutral-700" : "bg-white dark:bg-neutral-800"
                  }`}
                  onClick={() => handleSelectChange(num)}
                >
                  {num} Guests
                </button>
              ))}
          </div>
        </div>
      </div>
      <Toaster richColors /> 
    </>
  );
};

export default GuestsInput;
