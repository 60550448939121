import React, { useState } from "react";
import GuestsInput from "../GuestsInput";
import LocationInput from "../LocationInput";
import DatesRangeInput from "../DatesRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { useSearchContext } from "context/search";

interface StaySearchFormProps {
  closeModal: () => void;
}

const StaySearchForm: React.FC<StaySearchFormProps> = ({ closeModal }) => {
  const [fieldNameShow, setFieldNameShow] = useState<"location" | "dates" | "guests" | null>("location");
  const { location, setLocation, dates, guests, setGuests } = useSearchContext();
  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));

  const formatDate = (date: Date | null): string => {
    if (!date) return "Add Date";
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div className={`w-full bg-white dark:bg-neutral-800 ${isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"}`}>
        {!isActive ? (
          <button className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow("location")}>
            <span className="text-neutral-400">Where</span>
            <span>{location || "Location"}</span>
          </button>
        ) : (
          <LocationInput defaultValue={location} onChange={(value) => {
            setLocation(value);
            setFieldNameShow("dates");
            ;
          }} closeModal={closeModal}/>
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";
    return (
      <div className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"}`}>
        {!isActive ? (
          <button className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow("dates")}>
            <span className="text-neutral-400">When</span>
            <span>
              {formatDate(dates?.startDate) || "Add Date"}
            </span>
          </button>
        ) : (
          <DatesRangeInput  closeModal={() => setFieldNameShow(null)} />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";
    let guestSelected = "";
    if (guestInput.guestAdults || guestInput.guestChildren) {
      const guest = (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
      guestSelected += `${guest} guests`;
    }
    if (guestInput.guestInfants) {
      guestSelected += `, ${guestInput.guestInfants} infants`;
    }
  
    return (
      <div className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"}`}>
        {!isActive ? (
          <button className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow("guests")}>
            <span className="text-neutral-400">Guests</span>
            <span>{guests ? `${guests} guests` : `Add guests`}</span>
          </button>
        ) : (
          <GuestsInput useMaxGuests={true} closeModal={() => setFieldNameShow(null)} /> 
        )}
      </div>
    );
  };
  

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocation()}
        {renderInputDates()}
        {renderInputGuests()}
      </div>
    </div>
  );
};

export default StaySearchForm;
