import React, { FC, useState } from "react";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useFirebase } from "../../context/firebase";
import SyncLoader from "react-spinners/SyncLoader"; // Import SyncLoader
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism"; // For background effect
import logoImg from "images/login.png"; // Import the logo image
import { Toaster, toast } from 'sonner'; // Import Toaster and toast from sonner

export interface PageLoginProps {
  className?: string;
}

// Firebase error messages mapping
const getFriendlyErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case "auth/invalid-credential":
      return "Invalid credentials. Please check your email and password.";
    case "auth/user-not-found":
      return "No user found with this email.";
    case "auth/wrong-password":
      return "Incorrect password. Please try again.";
    case "auth/too-many-requests":
      return "Too many unsuccessful attempts. Please try again later.";
    case "auth/network-request-failed":
      return "Network error. Please check your connection.";
    default:
      return "An error occurred. Please try again.";
  }
};

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const firebase = useFirebase();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await firebase.signinUser(email, password);
      console.log("Login successful");

      const from = location.state?.from || "/"; // Default to home if there's no referrer
      navigate(from);
    } catch (error: any) {
      const friendlyMessage = getFriendlyErrorMessage(error.code); // Map error code to friendly message
      toast.error(friendlyMessage); // Show friendly error toast
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleLoginWithGoogle = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await firebase.signinWithGoogle();
      console.log("Login with Google successful");

      const from = location.state?.from || "/"; // Default to home if there's no referrer
      navigate(from);
    } catch (error: any) {
      const friendlyMessage = getFriendlyErrorMessage(error.code); // Map error code to friendly message
      toast.error(friendlyMessage); // Show friendly error toast
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>tayari. | Login</title>
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
        <meta
          name="description"
          content="Log in to your Tayari account to manage your venue bookings, explore venues, and access personalized recommendations for your events."
        />
      </Helmet>


      {/* Toaster for displaying toast messages */}
      <Toaster richColors />

      {loading ? (
        // Show the SyncLoader while loading
        <div className="flex justify-center items-center min-h-screen">
          <SyncLoader color={"#ffd02b"} loading={loading} size={15} margin={2} />
        </div>
      ) : (
        <div className="min-h-screen flex justify-center items-center overflow-hidden px-4">
          {/* Set the login box with responsive design */}
          <div className="w-full max-w-lg md:max-w-md lg:max-w-lg h-auto bg-white/30 dark:bg-neutral-900/30 backdrop-blur-md rounded-xl shadow-xl shadow-yellow-500/15 dark:shadow-yellow-500/15 p-8 md:p-12 lg:p-16">
            <BgGlassmorphism className="fixed inset-0 overflow-hidden -z-10 rounded-lg" />

            {/* Replace the text with the logo */}
            <div className="flex justify-start mb-8">
              <Link to="/"> {/* Wrap the image inside the Link component */}
                <img
                  src={logoImg}
                  alt="Logo"
                  className="w-32 md:w-40 h-auto" // Adjust width for responsiveness
                />
              </Link>
            </div>

            <div className="space-y-6">
              <div className="grid gap-3">
                <button
                  onClick={handleLoginWithGoogle}
                  className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                >
                  <img
                    className="flex-shrink-0"
                    src={googleSvg}
                    alt="Continue with Google"
                  />
                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                    Continue with Google
                  </h3>
                </button>
              </div>
              {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              {/* FORM */}
              <form onSubmit={handleLogin} className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1 rounded-lg"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                  </span>
                  <Input
                    type="password"
                    className="mt-1 rounded-lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </label>
                <Link to="/forgot-pass" className="text-sm text-end text-blue-600 hover:text-blue-700 hover:underline transition-all">
                  Forgot password?
                </Link>
                <ButtonPrimary className="flex-grow text-center text-sm font-medium text-neutral-100 dark:text-neutral-300 sm:text-sm rounded-lg" type="submit">Login</ButtonPrimary>
              </form>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                New user? {` `}
                <Link to="/signup" className="hover:underline text-blue-600 hover:text-blue-700 transition-all">Create an account</Link>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageLogin;
