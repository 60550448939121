import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ForgotPassword from "pages/forgotPassword";
import PrivacyPolicy from "containers/privacypolicy/privacypolicy";
import termsandconditions from "containers/termsandconditions/termsandconditions";
import refundpolicy from "containers/refundpolicy/refundpolicy";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/home-1-header-2", exact: true, component: PageHome },
  { path: "/home-3", component: PageHome3 },
  { path: "/listing-stay", component: ListingStayPage },
  { path: "/listing-vendor/:venueName/:type/:vendorId", component: ListingStayPage },
  { path: "/search-results", component: ListingStayMapPage },
  { path: "/listing-stay-detail", component: ListingStayDetailPage },
  { path: "/listing-vendor-detail/:venueName/:vendorId/:type/option/:optionName/:hallId", component: ListingStayDetailPage },
  { path: "/listing-vendor-detail/:venueName/:vendorId/:type/menu/:menuName/:menuId", component: ListingStayDetailPage },
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-and-conditions", component: termsandconditions }, 
  { path: "/refund-policy", component: refundpolicy }, 
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/forgot-pass", component: ForgotPassword }
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <RoutesWrapper WIN_WIDTH={WIN_WIDTH} />
    </BrowserRouter>
  );
};

interface FooterWrapperProps {
  WIN_WIDTH: number;
}

const RoutesWrapper: React.FC<FooterWrapperProps> = ({ WIN_WIDTH }) => {
  const location = useLocation();

  // Determine whether to hide the header and footer for specific routes
  const hideHeaderFooter = ["/login", "/signup", "/forgot-pass"].includes(location.pathname);

  return (
    <>
      {!hideHeaderFooter && <SiteHeader />}

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {!hideHeaderFooter && <FooterWrapper WIN_WIDTH={WIN_WIDTH} />}
    </>
  );
};

const FooterWrapper: React.FC<FooterWrapperProps> = ({ WIN_WIDTH }) => {
  return (
    <>
      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </>
  );
};

export default MyRoutes;
