import React, { createContext, useState, FC, ReactNode, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL } from "backendUrl";
import { Hall, Menu, PlaceReview } from 'data/types';
import { v4 as uuidv4 } from 'uuid';

interface AddingPlaceContextType {
  
  placeName: string;
  setPlaceName: (name: string) => void;
  vendorEmail: string;
  setVendorEmail: (email: string) => void;
  vendorContactNumber: string;
  setVendorContactNumber: (number: string) => void;
  placeId: string;
  setPlaceId: (id: string) => void;
  halls: Hall[];
  setHalls: (halls: Hall[]) => void;
  menus: Menu[];
  setMenus: (menus: Menu[]) => void;
  images: string[];
  setImages: (images: string[]) => void;
  address: string;
  websiteUri: string;
  googleMapsUri: string;
  rating: number;
  userRatingCount: number;
  googleImages: string[];
  featured: boolean;
  setFeatured: (featured: boolean) => void;
  latitude: number | null;
  longitude: number | null;
  fetchPlaceDetails: (placeId: string) => void;
  createPlace: () => void;
  addHallToPlace: (placeId: string, hall: Hall) => Promise<void>; // New function to add halls
  videos: string[];
  setVideos: (videos: string[]) => void;
  placeReviews: PlaceReview[];
  setPlaceReviews: (placeReviews: PlaceReview[]) => void;
  type: string;
  setType: (type: string) => void;
  cancellationPolicy: string;
  setCancellationPolicy: (policy: string) => void;
  discountPercentage: number;
  setDiscountPercentage: (percentage: number) => void;
  taxAndFeesPercentage: number;
  setTaxAndFeesPercentage: (percentage: number) => void;
  fixedPrice: boolean;
  setFixedPrice: (fixedPrice: boolean) => void;
  isPerPerson: boolean;
  setIsPerPerson: (isPerPerson: boolean) => void;
  menuBased: boolean;
  setMenuBased: (menuBased: boolean) => void;
  amenities: { name: string; icon: string }[];
  setAmenities: (amenities: { name: string; icon: string }[]) => void;
  hasBasePrice: boolean;
  setHasBasePrice: (hasBasePrice: boolean) => void;
  basePrice: number;
  setBasePrice: (basePrice: number) => void;
  hasRange: boolean;
  setHasRange: (hasRange: boolean) => void;
  priceRange: string;
  setPriceRange: (priceRange: string) => void;
  placeType: string;
  setPlaceType: (type: string) => void;
  placeNameWithoutGoogle: string;
  setPlaceNameWithoutGoogle: (name: string) => void;
  setAddress: (name: string) => void;
  amenitiesHall: { name: string; icon: string }[];
  setAmenitiesHall: (amenities: { name: string; icon: string }[]) => void;
  menuImages: string[]; // New state for menu images
  setMenuImages: (menuImages: string[]) => void; // Setter for new state
  menuDescription: string; // Add this line
  setMenuDescription: (description: string) => void; // Add this setter
}

const AddingPlaceContext = createContext<AddingPlaceContextType | undefined>(undefined);

export const AddingPlaceProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [placeName, setPlaceName] = useState<string>("");
  const [vendorEmail, setVendorEmail] = useState<string>("");
  const [vendorContactNumber, setVendorContactNumber] = useState<string>("");
  const [placeId, setPlaceId] = useState<string>("");
  const [halls, setHalls] = useState<Hall[]>([]);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [address, setAddress] = useState<string>("");
  const [websiteUri, setWebsiteUri] = useState<string>("");
  const [googleMapsUri, setGoogleMapsUri] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [userRatingCount, setUserRatingCount] = useState<number>(0);
  const [googleImages, setGoogleImages] = useState<string[]>([]);
  const [featured, setFeatured] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [videos, setVideos] = useState<string[]>([]);
  const [placeReviews, setPlaceReviews] = useState<PlaceReview[]>([]);
  const [type, setType] = useState<string>("");
  const [cancellationPolicy, setCancellationPolicy] = useState<string>("");
  const [discountPercentage, setDiscountPercentage] = useState<number>(0);
  const [taxAndFeesPercentage, setTaxAndFeesPercentage] = useState<number>(0);
  const [fixedPrice, setFixedPrice] = useState<boolean>(false);
  const [isPerPerson, setIsPerPerson] = useState<boolean>(false);
  const [menuBased, setMenuBased] = useState<boolean>(false);
  const [amenities, setAmenities] = useState<{ name: string; icon: string }[]>([]);
  const [hasBasePrice, setHasBasePrice] = useState<boolean>(false);
  const [basePrice, setBasePrice] = useState<number>(0);
  const [hasRange, setHasRange] = useState<boolean>(false);
  const [priceRange, setPriceRange] = useState<string>("");
  const [placeType, setPlaceType] = useState<string>("googlePlace");
  const [placeNameWithoutGoogle, setPlaceNameWithoutGoogle] = useState<string>("");
  const [amenitiesHall, setAmenitiesHall] = useState<{ name: string; icon: string }[]>([]);
  const [menuImages, setMenuImages] = useState<string[]>([]); // New state for menu images
  const [menuDescription, setMenuDescription] = useState<string>(""); // State for menu description

  useEffect(() => {
    localStorage.setItem("placeName", placeName);
  }, [placeName]);

  useEffect(() => {
    localStorage.setItem("vendorEmail", vendorEmail);
  }, [vendorEmail]);

  useEffect(() => {
    localStorage.setItem("vendorContactNumber", vendorContactNumber);
  }, [vendorContactNumber]);

  useEffect(() => {
    localStorage.setItem("placeId", placeId);
  }, [placeId]);

  useEffect(() => {
    localStorage.setItem("halls", JSON.stringify(halls));
  }, [halls]);

  useEffect(() => {
    localStorage.setItem("images", JSON.stringify(images));
  }, [images]);

  useEffect(() => {
    localStorage.setItem("address", address);
  }, [address]);

  useEffect(() => {
    localStorage.setItem("websiteUri", websiteUri);
  }, [websiteUri]);

  useEffect(() => {
    localStorage.setItem("googleMapsUri", googleMapsUri);
  }, [googleMapsUri]);

  useEffect(() => {
    localStorage.setItem("rating", rating.toString());
  }, [rating]);

  useEffect(() => {
    localStorage.setItem("userRatingCount", userRatingCount.toString());
  }, [userRatingCount]);

  useEffect(() => {
    localStorage.setItem("googleImages", JSON.stringify(googleImages));
  }, [googleImages]);

  useEffect(() => {
    localStorage.setItem("featured", JSON.stringify(featured));
  }, [featured]);

  useEffect(() => {
    localStorage.setItem("fixedPrice", JSON.stringify(fixedPrice));
  }, [fixedPrice]);

  useEffect(() => {
    localStorage.setItem("isPerPerson", JSON.stringify(isPerPerson));
  }, [isPerPerson]);

  useEffect(() => {
    localStorage.setItem("menuBased", JSON.stringify(menuBased));
  }, [menuBased]);

  useEffect(() => {
    localStorage.setItem("menus", JSON.stringify(menus));
  }, [menus]);

  useEffect(() => {
    localStorage.setItem("menuImages", JSON.stringify(menuImages)); // Sync menuImages to local storage
  }, [menuImages]);

  useEffect(() => {
    localStorage.setItem("menuDescription", menuDescription); // Sync menuDescription to local storage
  }, [menuDescription]);
  

  const addHallToPlace = async (placeId: string, hall: Hall) => {
    try {
      const response = await axios.post(`${BACKEND_URL}places/${placeId}/add-hall`, hall);
      console.log("Hall added to place:", response.data);

      // Optionally, update the halls state
      setHalls((prevHalls) => [...prevHalls, response.data]);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error adding hall to place:", error.response.data);
      } else {
        console.error("Error adding hall to place:", error);
      }
    }
  };


  const fetchPlaceDetails = async (placeId: string) => {
    try {
      const response = await axios.get(`${BACKEND_URL}place-details-from-google`, {
        params: { placeId },
      });

      const result = response.data;
      console.log("response", result);

      setAddress(result.formatted_address || "");
      setWebsiteUri(result.website || "");
      setGoogleMapsUri(result.url || "");
      setRating(result.rating || 0);
      setUserRatingCount(result.user_ratings_total || 0);
      setGoogleImages(result.google_images || []);
      setLatitude(result.map?.lat || null);
      setLongitude(result.map?.lng || null);
      setPlaceReviews(result.placeReviews || []);
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  

  const createPlace = async () => {
    try {
      const [displayName, ...addressParts] = placeName.split(',');

      const finalPlaceId = placeId || uuidv4();

      const placeResponse = await axios.post(`${BACKEND_URL}placeNormal`, {
        id: finalPlaceId,
        name: placeType === "googlePlace" ? displayName.trim() : placeNameWithoutGoogle,
        address: placeType === "googlePlace" ? placeName : address,
        website_uri: placeType === "googlePlace" ? websiteUri : "",
        google_maps_uri: placeType === "googlePlace" ? googleMapsUri : "",
        vendor_email: vendorEmail,
        vendor_contact_number: vendorContactNumber,
        seating_capacity: halls.reduce((total, hall) => total + hall.seating_capacity, 0),
        rating: placeType === "googlePlace" ? rating : 0,
        userRatingCount: placeType === "googlePlace" ? userRatingCount : 0,
        google_images: placeType === "googlePlace" ? googleImages : [],
        images,
        featured,
        map: placeType === "googlePlace" ? {
          lat: latitude,
          lng: longitude,
        } : {},
        placeReviews: placeType === "googlePlace" ? placeReviews : [],
        type,
        cancellation_policy: cancellationPolicy,
        discount_percentage: discountPercentage,
        tax_and_fees_percentage: taxAndFeesPercentage,
        fixed_price: fixedPrice,
        is_perperson: isPerPerson,
        menu_based: menuBased,
        amenities,
      });

      const place = placeResponse.data;
      console.log("Place created:", place);

      const hallIds = [];
      const menuIds = [];

      for (const hall of halls) {
        try {
          const hallResponse = await axios.post(`${BACKEND_URL}hall`, {
            ...hall,
            place: place._id,
            hall_fixedPrice: hall.hall_fixedPrice,
            hall_isperperson: hall.hall_isperperson,
            amenitiesHall,
          });
          console.log("Hall created:", hallResponse.data);
          hallIds.push(hallResponse.data._id);
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            console.error("Error creating hall:", hall, error.response.data);
          } else {
            console.error("Error creating hall:", hall, error);
          }
        }
      }

      for (const menu of menus) {
        try {
          const menuResponse = await axios.post(`${BACKEND_URL}menu`, {
            ...menu,
            place: place._id,
            menu_images: menuImages, // Include menu images
            menu_description: menu.menu_description, // Include menu description
          });
          console.log("Menu created:", menuResponse.data);
          menuIds.push(menuResponse.data._id);
        } catch (error) {
          if (axios.isAxiosError(error) && error.response) {
            console.error("Error creating menu:", menu, error.response.data);
          } else {
            console.error("Error creating menu:", menu, error);
          }
        }
      }      

      if (hallIds.length > 0 || menuIds.length > 0) {
        await axios.put(`${BACKEND_URL}places/${place._id}`, {
          halls: hallIds,
          menus: menuIds,
        });
      }

      console.log("Place, halls, and menus created successfully.");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error("Error creating place, halls, and menus:", error.response.data);
      } else {
        console.error("Error creating place, halls, and menus:", error);
      }
    }
  };

  

  useEffect(() => {
    if (placeId && placeType === "googlePlace") {
      fetchPlaceDetails(placeId);
    }
  }, [placeId, placeType]);

  return (
    <AddingPlaceContext.Provider
      value={{
        addHallToPlace,  // Add the new function to the context
        placeName,
        setPlaceName,
        vendorEmail,
        setVendorEmail,
        vendorContactNumber,
        setVendorContactNumber,
        placeId,
        setPlaceId,
        halls,
        setHalls,
        menus,
        setMenus,
        images,
        setImages,
        address,
        websiteUri,
        googleMapsUri,
        rating,
        userRatingCount,
        googleImages,
        featured,
        setFeatured,
        latitude,
        longitude,
        fetchPlaceDetails,
        createPlace,
        videos,
        setVideos,
        placeReviews,
        setPlaceReviews,
        type,
        setType,
        cancellationPolicy,
        setCancellationPolicy,
        discountPercentage,
        setDiscountPercentage,
        taxAndFeesPercentage,
        setTaxAndFeesPercentage,
        fixedPrice,
        setFixedPrice,
        isPerPerson,
        setIsPerPerson,
        menuBased,
        setMenuBased,
        amenities,
        setAmenities,
        hasBasePrice,
        setHasBasePrice,
        basePrice,
        setBasePrice,
        hasRange,
        setHasRange,
        priceRange,
        setPriceRange,
        placeType,
        setPlaceType,
        placeNameWithoutGoogle,
        setPlaceNameWithoutGoogle,
        setAddress,
        amenitiesHall,
        setAmenitiesHall,
        menuImages, // Provide menuImages state to context
        setMenuImages, // Provide setMenuImages state setter to context
        menuDescription,
        setMenuDescription,
      }}
    >
      {children}
    </AddingPlaceContext.Provider>
  );
};

export const useAddingPlaceContext = () => {
  const context = React.useContext(AddingPlaceContext);
  if (!context) {
    throw new Error("useAddingPlaceContext must be used within an AddingPlaceProvider");
  }
  return context;
};
