import React, { FC } from "react";
import rightImgPng from "images/our-features.webp";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">

        </span>

        <h2 className="font-semibold text-4xl mt-5">Book your <span className="text-custom-color text-yellow-500">Destination Wedding</span>  </h2>
        <h3 className="font-bold text-2xl text-teal-500 mt-5">Coming Soon! </h3>


        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="Venue" />
            <span className="block text-xl font-semibold">
              Breathtaking Venues
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Discover breathtaking venues tailored to your dream destination wedding.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Caterer " />
            <span className="block text-xl font-semibold">
              World-class Caterers           
               </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Delight guests with world-class cuisine served at your dream destination wedding.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="red" name="Decorator" />
            <span className="block text-xl font-semibold">
              Expert Decorators
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Elevate your wedding with expert decorators who bring your vision to life
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
