import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";

export interface PageAboutProps {
  className?: string;
}


const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>tayari. | About Us</title>
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
        <meta 
          name="description" 
          content="Learn more about Tayari, your go-to platform for venue booking in Pakistan. Discover our mission and how we connect you with the best venues for your events." 
        />
        <link rel="canonical" href="https://tayaripk.com" />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading="👋 About Us."
          btnText=""
          subHeading="At Tayari, our mission is to connect you with the best venues that match your specific needs and preferences. Finding the perfect venue should be exciting, not stressful. That's why we've created a user-friendly platform offering a diverse range of venues, from luxurious ballrooms and chic rooftop bars to cozy garden spaces."
        />

        <div className="relative py-28">
          <BackgroundSection />
          <SectionFounder />
        </div>
        <div className="relative py-20">
          <SectionStatistic />
        </div>

      </div>
    </div>
  );
};

export default PageAbout;
