import React from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSearchContext } from "context/search";
import { useFirebase } from "context/firebase";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from 'sonner'; // Import Toaster and toast from sonner
import ModalSelectGuests from "components/ModalSelectGuests";

const converSelectedDateToString = (date: Date): string => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};

interface MobileFooterStickyProps {
  loading: boolean;
}

const MobileFooterSticky: React.FC<MobileFooterStickyProps> = ({ loading }) => {
  const { selectedDate, selectedHall, guests, selectedVenue, selectedMenu } = useSearchContext();
  const { getCurrentUser } = useFirebase();
  const navigate = useNavigate();

  const isPerPerson = selectedHall?.hall_isperperson || selectedVenue?.is_perperson;
  const isMenuBased = selectedVenue?.menu_based || selectedVenue?.type === "Caterers";
  const guestCount = isNaN(guests) ? 0 : guests;

  // Determine the price to display, providing a default value of 0 if undefined
  const displayedPrice = isMenuBased
    ? selectedMenu?.price ?? 0
    : selectedHall?.hasBasePrice
    ? selectedHall.basePrice
    : selectedHall?.price ?? 0;

  // Determine the total price based on whether it's per-person
  const totalPrice = isPerPerson ? displayedPrice : displayedPrice;

  // Determine the text for displaying guests
  const displayGuests = guestCount > 0 ? `${guestCount} Guests` : "Add Guests";

  if (loading) {
    return null; // Don't render if the page is still loading
  }

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        {/* Place Toaster here to appear above the price */}
        <div>
          <span className="block text-xl font-semibold">
            PKR {totalPrice.toLocaleString()}
            {(isPerPerson || selectedVenue?.menu_based) && (
              <span className="text-xs font-medium text-neutral-500">
                /per person
              </span>
            )}
          </span>
          <ModalSelectDate
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {selectedDate
                  ? converSelectedDateToString(selectedDate)
                  : "Add Date"}
              </span>
            )}
          />

          <ModalSelectGuests
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm font-medium underline cursor-pointer"
              >
                {displayGuests}
              </span>
            )}
          />
        </div>
        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
              onClick={() => {
                const user = getCurrentUser();

                // Determine the appropriate error message
                if (!selectedDate && guestCount === 0) {
                  toast.error("Please select a date and guest count.");
                  return;
                } else if (!selectedDate) {
                  toast.error("Please select a date.");
                  return;
                } else if (guestCount === 0) {
                  toast.error("Please select the number of guests.");
                  return;
                }

                if (!user) {
                  navigate("/login", {
                    state: { from: window.location.pathname },
                  }); // Pass the current path for redirect after login
                  return;
                }

                // Open the reservation modal if all conditions are met
                openModal();
              }}
            >
              Book Now
            </ButtonPrimary>
          )}
        />
      </div>
      <Toaster richColors toastOptions={{ style: { marginBottom: '4.5rem' } }} /> 
    </div>
  );
};

export default MobileFooterSticky;
