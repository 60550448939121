import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFirebase } from '../context/firebase';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism'; // For background effect
import SyncLoader from 'react-spinners/SyncLoader'; // Import SyncLoader
import logoImg from 'images/forgot-password.png'; // Replace with the correct logo image
import { Link } from 'react-router-dom'; // Import Link for navigation
import { Toaster, toast } from 'sonner'; // Import Toaster and toast from sonner

// Firebase error messages mapping
const getFriendlyErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case "auth/invalid-email":
      return "The email address is invalid. Please check your input.";
    case "auth/user-not-found":
      return "No account found with this email address.";
    case "auth/network-request-failed":
      return "Network error. Please check your connection.";
    default:
      return "An error occurred. Please try again.";
  }
};

const ForgotPassword = () => {
  const firebase = useFirebase();
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await firebase.sendPasswordResetEmail(email);
      setMessage('Password reset email sent. Please check your inbox.');
      setError('');
      toast.success('Password reset email sent successfully!');
    } catch (error: any) {
      const friendlyMessage = getFriendlyErrorMessage(error.code); // Map error code to friendly message
      toast.error(friendlyMessage); // Show friendly error toast
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="nc-ForgotPassword" data-nc-id="ForgotPassword">
      <Helmet>
        <title>tayari. | Forgot Password</title>
      </Helmet>

      {/* Toaster for displaying toast messages */}
      <Toaster richColors />

      {loading ? (
        // Show the SyncLoader while loading
        <div className="flex justify-center items-center min-h-screen">
          <SyncLoader color={"#ffd02b"} loading={loading} size={15} margin={2} />
        </div>
      ) : (
        <div className="min-h-screen flex justify-center items-center overflow-hidden px-4">
          {/* Set the forgot password box with responsive design */}
          <div className="w-full max-w-lg md:max-w-md lg:max-w-lg h-auto bg-white/30 dark:bg-neutral-900/30 backdrop-blur-md rounded-xl shadow-xl shadow-yellow-500/15 dark:shadow-yellow-500/15 p-8 md:p-12 lg:p-16">
            <BgGlassmorphism className="fixed inset-0 overflow-hidden -z-10 rounded-lg" />

            {/* Replace the text with the logo */}
            <div className="flex justify-start mb-8">
              <Link to="/"> {/* Wrap the image inside the Link component */}
                <img
                  src={logoImg}
                  alt="Forgot password Logo"
                  className="w-32 md:w-40 h-auto" // Adjust width for responsiveness
                />
              </Link>
            </div>

            <div className="space-y-6">
              <h2 className="text-2xl font-semibold text-center text-neutral-800 dark:text-neutral-200 mb-10">
                Forgot your Password?
              </h2>
              <p className="text-center text-neutral-700 dark:text-neutral-300">
                Enter the email address where you'd like us to send your password reset instructions.
              </p>

              <form onSubmit={handleForgotPassword} className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1 rounded-lg"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
                <ButtonPrimary className="flex-grow text-center text-sm font-medium text-neutral-100 dark:text-neutral-300 sm:text-sm rounded-lg" type="submit">
                  Send Reset Email
                </ButtonPrimary>
              </form>

              <div className="text-center mt-4">
                <span className='text-md'>Back to </span>
                <Link to="/login" className="text-blue-600 hover:underline text-md">
                   Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
