import React, { FC, useState } from "react";
import { useSearchContext } from "context/search";
import { useFirebase } from "context/firebase";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Toaster, toast } from 'sonner'; // Import Toaster and toast from sonner
import "react-toastify/dist/ReactToastify.css";

export interface SectionHeroArchivePageHallsProps {
  className?: string;
}

const SectionHeroArchivePageHalls: FC<SectionHeroArchivePageHallsProps> = ({
  className = "",
}) => {
  const { selectedVenue, savePlace } = useSearchContext();
  const { getCurrentUser } = useFirebase();
  const [isSaved, setIsSaved] = useState(false);

  const handleSavePlace = async () => {
    const user = getCurrentUser();
    if (user && selectedVenue) {
      await savePlace(selectedVenue.id, user.uid);
      setIsSaved(true);
      toast.success("Added to wishlist!");
    } else {
      toast.error("Please log in first");
    }
  };

  if (!selectedVenue) {
    return <div>No venue selected</div>;
  }

  // Determine the images to use, prioritizing selectedVenue.images
  const images = selectedVenue.images?.length
    ? selectedVenue.images
    : selectedVenue.google_images;

  // Determine the count of options based on the venue type
  const optionCount =
    selectedVenue.type === "Caterers"
      ? selectedVenue.menus.length || 0
      : selectedVenue.halls.length || 0;

  // Determine the label based on the venue type and option count
  const optionLabel =
    selectedVenue.type === "Caterers"
      ? optionCount === 1
        ? "Menu"
        : "Menus"
      : optionCount === 1
      ? "Option"
      : "Options";

  return (
    <div
      className={`nc-SectionHeroArchivePageHalls flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePageHalls"
    >
      <Toaster richColors /> 
      <div className="flex flex-col lg:flex-row lg:items-start">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0 max-h-96">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {selectedVenue.name}
          </h2>
          <div className="text-lg text-neutral-500 dark:text-neutral-400">
            {selectedVenue.address}
          </div>
          <div className="flex items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            <i className="text-2xl las la-home"></i>
            <span className="ml-2.5">
              {optionCount} {optionLabel}
            </span>
          </div>

          <div onClick={handleSavePlace}>
            <ButtonPrimary>
              {isSaved ? "Saved in Wishlist" : "Add to Wishlist"}
            </ButtonPrimary>
          </div>
        </div>

        {/* Conditionally render images */}
        <div className="flex-grow">
          {images.length === 1 ? (
            <div className="h-80 w-full">
              <img
                src={images[0] || "/images/default.jpg"}
                alt="Venue"
                className="object-cover w-full h-full rounded-md"
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 lg:gap-6">
              <div className="col-span-2 h-80 w-full">
                <img
                  src={images[0] || "/images/default.jpg"}
                  alt="Venue 1"
                  className="object-cover w-full h-full rounded-md"
                />
              </div>
              <div className="h-60 w-full">
                <img
                  src={images[1] || "/images/default.jpg"}
                  alt="Venue 2"
                  className="object-cover w-full h-full rounded-md"
                />
              </div>
              <div className="h-60 w-full">
                <img
                  src={images[2] || "/images/default.jpg"}
                  alt="Venue 3"
                  className="object-cover w-full h-full rounded-md"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHeroArchivePageHalls;
