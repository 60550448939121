import React, { FC, useEffect } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./StaySearchForm.css";

const StaySearchForm: FC<{}> = () => {
  const navigate = useNavigate();
  const { location, guests, dates, searchPlaces } = useSearchContext();

  useEffect(() => {
    console.log("Location changed:", location);
  }, [location]);

  useEffect(() => {
    console.log("date changed:", dates);
  }, [dates]);

  useEffect(() => {
    console.log("number of guests changed:", guests);
  }, [guests]);


  return (
    <div className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      <LocationInput className="flex-[1.5]" />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <StayDatesRangeInput className="flex-1" />
      <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
      <GuestsInput className="flex-1" />
    </div>
  );
};

export default StaySearchForm;
