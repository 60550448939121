import { Tab } from "@headlessui/react";
import React, { FC, Fragment, useState, useEffect } from "react";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSearchContext } from "context/search";
import axios from "axios";
import { useFirebase } from "context/firebase";
import { BACKEND_URL } from "backendUrl";
import { GoogleCalendarEvent, VendorResponse } from "data/types";
import { Helmet } from "react-helmet";
import { Toaster, toast } from 'sonner';
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import ClipLoader from "react-spinners/ClipLoader";
import getAccessToken from './getAccessToken'; // Adjust the path based on your file structure

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
}) => {
  const {
    selectedVenue,
    guests,
    selectedHall,
    dates,
    selectedDate,
    hallDayBooked,
    hallNightBooked,
    selectedMenu,
    basePrice,
    setSelectedDate
  } = useSearchContext();
  const { getAuth } = useFirebase();
  const [user, setUser] = useState<any>(null);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerContact, setCustomerContact] = useState("");
  const [bookingTime, setBookingTime] = useState("day");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [bookingInProgress, setBookingInProgress] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Pay Now');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [getAuth]);

  const converSelectedDateToString = (date: Date): string => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  const handleBooking = async () => {
    if (bookingInProgress) return; // Prevent duplicate bookings
    setBookingInProgress(true);

    let isValid = true;
    if (!customerName) {
      setNameError(true);
      isValid = false;
    }
    if (!customerEmail) {
      setEmailError(true);
      isValid = false;
    }
    if (!customerContact) {
      setContactError(true);
      isValid = false;
    }
    if (!isValid) {
      toast.error("Please fill in all the required fields.");
      setBookingInProgress(false);
      return;
    }

    try {
      const startDate = new Date(selectedDate || Date.now());
      const endDate = new Date(selectedDate || Date.now());

      if (bookingTime === "day") {
        startDate.setHours(1, 0, 0, 0);
        endDate.setHours(11, 0, 0, 0);
      } else {
        startDate.setHours(13, 0, 0, 0);
        endDate.setHours(23, 0, 0, 0);
      }

      // Determine the name to use in the event based on the venue type
      const eventName =
        selectedVenue?.type === "Caterers" ? selectedMenu?.name : selectedHall?.name;

      const event = {
        summary: `Booking for ${eventName}`,
        description: `Booking Details:
          - Venue Name: ${selectedVenue?.name}
          - Hall Name: ${eventName}
          - Booked by: ${user.email}
          - Guests: ${guests}
          - Customer Name: ${customerName}
          - Email: ${customerEmail}
          - Contact: ${customerContact}`,
        start: { dateTime: startDate.toISOString() },  // UTC format
        end: { dateTime: endDate.toISOString() },      // UTC format
      };


      const vendorEmail = selectedVenue?.vendor_email;
      const vendorContactNumber = selectedVenue?.vendor_contact_number || ""; // Get the vendor contact number

      //   if (!vendorContactNumber) {
      //     throw new Error('Vendor contact number not available.');
      // }

      const response = await axios.get<VendorResponse>(
        `${BACKEND_URL}vendor-by-email`,
        {
          params: { email: vendorEmail },
        }
      );
      const accessToken = response.data.accessToken;

      const calendarEventResponse = await axios.post(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
        event,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const googleCalendarEventId = calendarEventResponse.data.id;

      // Determine base price
      const basePrice = selectedHall?.hasBasePrice
        ? selectedHall.basePrice
        : selectedHall?.price || 0;

      // Calculate menu price total if the venue is menu-based
      const menuPriceTotal = (selectedMenu?.price || 0) * guests;

      // Determine venue rent (display price) based on the type of pricing structure
      let venueRent = basePrice;
      if (selectedHall?.hall_isperperson) {
        venueRent = (selectedHall.price || 0) * guests; // Per-person pricing
      } else if (selectedVenue?.menu_based) {
        venueRent = menuPriceTotal; // Menu-based pricing
      }

      // Calculate advance payment
      let advancePayment = (venueRent * (selectedHall?.advance_percentage || 0)) / 100;

      // Adjust order total if advance payment is 0
      let orderTotal = venueRent - advancePayment;
      if (advancePayment === 0) {
        advancePayment = venueRent; // Set advance to full venue rent
        orderTotal = 0; // Set remaining payment to 0
      }

      // Prepare booking data to be sent in the request
      const bookingData = {
        selected_hall:
          selectedVenue?.type === "Caterers" ? undefined : selectedHall,
        hall_name:
          selectedVenue?.type === "Caterers"
            ? selectedMenu?.name
            : selectedHall?.name,
        venue_type: selectedVenue?.type, // Set the venue type
        venue_name: selectedVenue?.name,
        customer_name: customerName,
        customer_email: customerEmail,
        customer_contact_number: customerContact,
        vendor_contact_number: vendorContactNumber,  // Include vendor contact number here
        selected_date: selectedDate,
        guests: guests,
        vendor_email: vendorEmail,
        google_calendar_event_id: googleCalendarEventId,
        payment_status: "pending",
        event_date: selectedDate,
        booking_time: bookingTime,
        venue_rent: venueRent,
        advance_rent: advancePayment,
        order_total: orderTotal, // Updated to reflect the correct order total
      };

      // If Pay Now is selected, start the payment process
      if (selectedPaymentMethod === "Pay Now") {
        // Send booking data to the backend
        const bookingResponse = await axios.post(
          `${BACKEND_URL}bookings`,
          bookingData
        );

        // Fetch the access token using the getAccessToken function
        const results = await getAccessToken(
          "23122",
          "5StHEhNZntcv87Q_Ch--1LZ",
          bookingResponse.data._id,
          bookingData.advance_rent
        );
        const parsedResult = JSON.parse(results);
        const token = parsedResult.accessToken.ACCESS_TOKEN;

        // Create a hidden form to submit details to PayFast
        const payFastDetails = {
          merchantId: 23122,
          merchantName: "Demo Merchant",
          token: token, // Use the token retrieved from the API
        };

        const form: HTMLFormElement = document.createElement("form");
        form.method = "POST";
        form.action =
          "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction";

        // Add form fields
        const fields: { [key: string]: string | number } = {
          MERCHANT_ID: payFastDetails?.merchantId,
          MERCHANT_NAME: payFastDetails?.merchantName,
          TOKEN: payFastDetails?.token,
          PROCCODE: "00",
          TXNAMT: bookingData.advance_rent,
          CUSTOMER_MOBILE_NO: customerContact,
          CURRENCY_CODE: "PKR",
          CUSTOMER_EMAIL_ADDRESS: customerEmail,
          TXNDESC: `Booking for ${selectedHall?.name}`,
          SUCCESS_URL: `${window.location.origin}/pay-done`,
          FAILURE_URL: `${window.location.origin}/`,
          BASKET_ID: bookingResponse.data._id,
          ORDER_DATE: new Date().toISOString(),
          CHECKOUT_URL: window.location.href,
        };

        // Create hidden input fields for form
        for (const key in fields) {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = key;
          input.value = fields[key].toString();
          form.appendChild(input);
        }

        // Append form to the document body
        document.body.appendChild(form);

        // Submit the form
        form.submit();

        // Remove the form from the DOM
        document.body.removeChild(form);
      } else {
        // For Book Appointment, only complete the booking
        await axios.post(`${BACKEND_URL}bookings`, bookingData);
        toast.success("Booking successful!");
        navigate("/pay-done");
      }

      // setSelectedDate(null);
    } catch (error) {
      console.error("Error creating event or processing payment:", error);
      toast.error("There was an error processing your booking.");
    } finally {
      setBookingInProgress(false); // Reset the bookingInProgress state
    }
  };



  const calculateAdvancePayment = () => {
    if (!selectedHall) return 0;
    return (selectedHall.price * selectedHall.advance_percentage) / 100;
  };

  const renderSidebar = () => {
    const guestCount = guests;
    const isMenuBased = selectedVenue?.menu_based || selectedVenue?.type === "Caterers";
    const isCaterer = selectedVenue?.type === "Caterers" && selectedMenu;

    // Determine the price to display based on menu-based or per-person hall pricing
    let displayPrice = 0;
    if (isMenuBased) {
      // For menu-based venues, calculate the total price as selected menu price x guest count
      displayPrice = (selectedMenu?.price || 0) * guestCount;
    } else if (selectedHall?.hall_isperperson) {
      // For halls with per-person pricing, calculate the total price as hall price x guest count
      displayPrice = (selectedHall?.price || 0) * guestCount;
    } else {
      // Default to base price or standard hall price
      displayPrice = selectedHall?.hasBasePrice ? selectedHall.basePrice : selectedHall?.price || 0;
    }

    // Calculate advance payment
    let advancePayment = (displayPrice * (selectedHall?.advance_percentage || 0)) / 100;

    // Adjust payable amount if advance payment is 0
    let payableAfterEvent = displayPrice - advancePayment;
    if (advancePayment === 0) {
      advancePayment = displayPrice; // Set advance to full venue rent
      payableAfterEvent = 0; // Set remaining payment to 0
    }

    if (!isCaterer && !selectedHall) {
      return <div>No hall selected</div>;
    }

    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <img
                alt=""
                className="absolute inset-0 object-cover"
                sizes="200px"
                src={
                  isCaterer
                    ? selectedMenu?.menu_images?.[0] || "/images/default.jpg"
                    : selectedHall?.images?.[0] || "/images/default.jpg"
                }
              />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {selectedVenue?.name}
              </span>
              <span className="text-base font-medium mt-1 block">
                {isCaterer ? selectedMenu?.name : selectedHall?.name}
              </span>
            </div>
            <span className="block text-sm text-neutral-500 dark:text-neutral-400">
              {guestCount} Guests
            </span>
            <div className="w-10 border-b border-neutral-200 dark:border-neutral-700"></div>
            <StartRating />
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price Detail</h3>
          {(isMenuBased || selectedHall?.hall_isperperson) && (
            <span className="flex justify-end text-sm text-neutral-500 dark:text-neutral-400">
              PKR {selectedMenu?.price?.toLocaleString() || selectedHall?.price?.toLocaleString()} x {guestCount.toLocaleString()} Guests
            </span>
          )}
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Venue Rent</span>
            <span>PKR {displayPrice.toLocaleString()}</span>
          </div>
          <div className="flex justify-between text-teal-500 dark:text-teal-500">
            <span>Payable Now</span>
            <span>PKR {advancePayment.toLocaleString()}</span>
          </div>
          <div className="flex justify-between text-teal-500 dark:text-teal-500">
            <span>Payable After Event</span>
            <span>PKR {payableAfterEvent.toLocaleString()}</span>
          </div>
          <p className="text-sm text-neutral-6000 dark:text-neutral-300">
            This amount will be paid at the venue after your event.
          </p>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Taxes & Fees</span>
            <span>-</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>You Pay</span>
            <span>PKR {advancePayment.toLocaleString()}</span>
          </div>
        </div>
      </div>
    );
  };



  const renderSidebarSm = () => {
    if (!selectedHall) {
      <div>No hall selected</div>;
    }

    const guestCount = guests;
    const isMenuBased = selectedVenue?.menu_based || selectedVenue?.type === "Caterers";

    // Determine the price to display based on menu-based, per-person hall pricing, or base price
    let displayPrice = 0;
    if (isMenuBased) {
      // For menu-based venues, calculate the total price as selected menu price x guest count
      displayPrice = (selectedMenu?.price || 0) * guestCount;
    } else if (selectedHall?.hall_isperperson) {
      // For halls with per-person pricing, calculate the total price as hall price x guest count
      displayPrice = (selectedHall?.price || 0) * guestCount;
    } else if (selectedHall?.hasBasePrice) {
      // If the hall has a base price, use the base price
      displayPrice = selectedHall.basePrice;
    } else {
      // Default to standard hall price
      displayPrice = selectedHall?.price || 0;
    }

    // Calculate advance payment
    let advancePayment = (displayPrice * (selectedHall?.advance_percentage || 0)) / 100;

    // Adjust payable amount if advance payment is 0
    let payableAfterEvent = displayPrice - advancePayment;
    if (advancePayment === 0) {
      advancePayment = displayPrice; // Set advance to full venue rent
      payableAfterEvent = 0; // Set remaining payment to 0
    }

    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price Details</h3>
          {(isMenuBased || selectedHall?.hall_isperperson) && (
            <span className="flex justify-end text-sm text-neutral-500 dark:text-neutral-400">
              PKR {selectedMenu?.price?.toLocaleString() || selectedHall?.price?.toLocaleString()} x {guestCount.toLocaleString()} Guests
            </span>
          )}
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Venue Rent</span>
            <span>PKR {displayPrice.toLocaleString()}</span>
          </div>
          <div className="flex justify-between text-teal-500 dark:text-teal-500">
            <span>Payable Now</span>
            <span>PKR {advancePayment.toLocaleString()}</span>
          </div>
          <div className="flex justify-between text-teal-500 dark:text-teal-500">
            <span>Payable After Event</span>
            <span>PKR {payableAfterEvent.toLocaleString()}</span>
          </div>
          <p className="text-sm text-neutral-6000 dark:text-neutral-300">
            This amount will be paid at the venue after your event.
          </p>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Taxes & Fees</span>
            <span>-</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>You Pay</span>
            <span>PKR {advancePayment.toLocaleString()}</span>
          </div>
        </div>
      </div>
    );
  };



  const validateContactNumber = (value: string) => {
    // Remove any non-numeric characters except for '+'
    const filteredValue = value.replace(/[^0-9+]/g, '');

    // Ensure that the filtered value only starts with +92 or 0 and has 11 digits
    const regex = /^(0|\+92)\d{10}$/;
    if (regex.test(filteredValue)) {
      setContactError(false);
    } else {
      setContactError(true);
    }
    setCustomerContact(filteredValue);
  };



  const renderMain = () => {
    const guestCount = selectedVenue?.type === "Venues" || selectedVenue?.type === "Photographers"
      ? selectedHall?.seating_capacity || 0
      : guests;


    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm & Pay
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <h3 className="text-2xl font-semibold">Your Booking</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
          <div className="flex flex-col lg:hidden sm:flex-row sm:items-center p-2">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <img
                  alt=""
                  className="absolute inset-0 object-cover"
                  sizes="200px"
                  src={
                    selectedVenue?.type === "Caterers" && (selectedMenu?.menu_images?.length ?? 0) > 0
                      ? selectedMenu?.menu_images[0] // Use the first image of the selected menu
                      : selectedHall?.images?.[0] || "/images/default.jpg" // Fallback to hall image or a default image
                  }
                />
              </div>
            </div>
            <div className="py-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {selectedVenue?.name}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {selectedVenue?.type === "Caterers" && selectedMenu?.name
                    ? selectedMenu.name // Display menu name if the venue is a caterer
                    : selectedHall?.name} {/* Display hall name otherwise */}
                </span>
              </div>

              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {guests} Guests
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating />
            </div>
          </div>
          <div className=" lg:mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700 overflow-hidden z-10">
            <ModalSelectDate
              renderChildren={({ openModal }) => (
                <button
                  className="text-left flex-1 p-5 flex justify-between space-x-5 cursor-not-allowed opacity-100"
                  type="button"
                  disabled
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Event Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {selectedDate
                        ? converSelectedDateToString(selectedDate)
                        : "Add Date"}
                    </span>
                  </div>
                </button>
              )}
            />
            <ModalSelectGuests
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  className="text-left flex-1 p-5 flex justify-between space-x-5 cursor-not-allowed opacity-100"
                  disabled
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${guests} Guests`}
                      </span>
                    </span>
                  </div>
                </button>
              )}
            />

          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Booking Details</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
          <form className="space-y-5">
            <div className="space-y-1">
              <Label>Name </Label>
              <Input
                placeholder="Enter your name"
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                  setNameError(false);
                }}
                className={nameError ? "border-red-500 dark:border-red-500" : ""}
              />

              {nameError && (
                <p className="text-red-500 text-xs ml-2">This field is required.</p>
              )}
            </div>
            <div className="space-y-1">
              <Label>Email </Label>
              <Input
                type="email"
                placeholder="Enter your email"
                value={customerEmail}
                onChange={(e) => {
                  setCustomerEmail(e.target.value);
                  setEmailError(false);
                }}
                className={emailError ? "border-red-500 dark:border-red-500" : ""}
              />
              {emailError && (
                <p className="text-red-500 text-xs ml-2">This field is required.</p>
              )}
            </div>
            <div className="space-y-1">
              <Label>Number</Label>
              <Input
                type="tel"
                placeholder="Enter your number"
                value={customerContact}
                onChange={(e) => validateContactNumber(e.target.value)}
                className={contactError ? "border-red-500 dark:border-red-500" : ""}
              />
              {contactError && (
                <p className="text-red-500 text-xs ml-2">
                  Please enter a valid number.
                </p>
              )}
            </div>
            <div>
              <div className="space-y-1">
                <Label>Event time</Label>
                <div className="flex items-center space-x-4">
                  {!hallDayBooked && (
                    <label className="inline-flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-teal-600 bg-gray-100 dark:bg-gray-700 rounded-full focus:outline-none focus:ring-0 appearance-none border-none"
                        checked={bookingTime === "day"}
                        onChange={() => setBookingTime("day")}
                      />
                      <span className="ml-2 text-neutral-500 dark:text-neutral-300 text-base">
                        Day
                      </span>
                    </label>
                  )}
                  {!hallNightBooked && (
                    <label className="inline-flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-teal-600 bg-gray-100 dark:bg-gray-500 rounded-full focus:outline-none focus:ring-0 appearance-none border-none"
                        checked={bookingTime === "night"}
                        onChange={() => setBookingTime("night")}
                      />
                      <span className="ml-2 text-neutral-500 dark:text-neutral-300 text-base">
                        Night
                      </span>
                    </label>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="">
          <div className="block lg:hidden">{renderSidebarSm()}</div>
        </div>
        <div>
          <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group
              onChange={(index) =>
                setSelectedPaymentMethod(index === 0 ? 'Pay Now' : 'Book Appointment')
              }
            >
              <Tab.List className="flex my-5 gap-1">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? 'bg-neutral-800 dark:bg-neutral-300 text-neutral-50 dark:text-neutral-900'
                        : 'text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                        }`}
                    >
                      Pay Now
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${selected
                        ? 'bg-neutral-800 dark:bg-neutral-300 text-neutral-50 dark:text-neutral-900'
                        : 'text-neutral-6000 dark:text-neutral-400 hover:bg-neutral-100 dark:hover:bg-neutral-800'
                        }`}
                    >
                      Book Appointment
                    </button>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="">
                  <div className="space-y-10">
                    <div className="p-5 border border-neutral-200 dark:border-neutral-700 rounded-lg bg-green-100 dark:bg-green-900 flex items-start">
                      <div className="mr-4">
                        <svg
                          className="w-8 h-8 text-green-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 className="text-lg font-medium text-neutral-700 dark:text-neutral-300 mb-2">
                          Confirmed Booking
                        </h4>
                        <p className="text-sm text-neutral-700 dark:text-neutral-300">
                          Your booking will be confirmed once the payment is successfully
                          completed. Please note that this booking is non-cancelable, and
                          the payment made now is an advance only; the remaining balance
                          will be paid at the venue.
                        </p>
                        <p className="text-sm text-neutral-700 dark:text-neutral-300 mt-2">
                          After payment, a receipt will be sent to you on WhatsApp & Email. Kindly
                          bring this receipt with you to the venue on the booking day.
                        </p>
                        <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                          Our representative will assist you with any additional details
                          and ensure a smooth payment process at the venue.
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="">
                  <div className="space-y-10">
                    <div className="p-5 border border-neutral-200 dark:border-neutral-700 rounded-lg bg-red-100 dark:bg-red-900 flex items-start">
                      <div className="mr-4">
                        <svg
                          className="w-8 h-8 text-red-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.257 3.099c.366-.446.986-.446 1.352 0l6.586 8.07c.384.47.048 1.174-.676 1.174H2.747c-.724 0-1.06-.703-.676-1.174l6.586-8.07zM9 9h2v2H9V9zm0 4h2v2H9v-2z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 className="text-lg font-medium text-neutral-700 dark:text-neutral-300 mb-2">
                          Appointment Information
                        </h4>
                        <p className="text-sm text-neutral-700 dark:text-neutral-300">
                          To complete your booking, you will need to visit the venue with
                          a representative from <a href="https://tayaripk.com/" target="_blank" rel="noopener noreferrer">
                            Tayari {''}
                          </a>
                          within 1 day. If the payment
                          process is not completed in this timeframe, your booking will be
                          canceled.
                        </p>
                        <p className="text-sm text-neutral-700 dark:text-neutral-300 mt-2">
                          Please keep the receipt that you will receive on WhatsApp & Email after
                          the booking is completed, and bring it with you to the venue.
                        </p>
                        <p className="text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                          Our representative will assist you with all the necessary
                          details and ensure a smooth payment process.
                        </p>
                      </div>
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary onClick={handleBooking} disabled={bookingInProgress}>
                {bookingInProgress ? (
                  <div className="flex items-center">
                    <ClipLoader color="#ffffff" size={20} />
                    <span className="ml-2">Processing...</span>
                  </div>
                ) : selectedPaymentMethod === 'Pay Now' ? (
                  'Proceed to Payment'
                ) : (
                  'Book Appointment'
                )}
              </ButtonPrimary>
            </div>
          </div>

        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <Helmet>
        <title>tayari. | Checkout</title>
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
        <meta
          name="description"
          content="Complete your venue booking with Tayari. Review your selected venue, confirm pricing and availability, and finalize your booking for your upcoming event."
        />
      </Helmet>

      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row justify-between">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block sticky top-[98px] h-[calc(100vh-4rem)] overflow-auto">

          {renderSidebar()}
        </div>
        <Toaster richColors />
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
