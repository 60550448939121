import React, { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { useSearchContext } from "context/search";
import { Toaster, toast } from 'sonner'; // Import Toaster and toast from sonner
import "react-toastify/dist/ReactToastify.css";

export interface GuestsInputProps {
  type?: string;
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const GuestsInput: React.FC<GuestsInputProps> = ({
  type = "Venues", 
  fieldClassName = "nc-hero-field-padding",
  className = "nc-flex-1",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
}) => {
  const { selectedHall, setGuests, guests } = useSearchContext();
  const [inputValue, setInputValue] = useState<number>(guests || 0);

  // Determine the maximum guest count based on seating_capacity or default to 1000 if it's 0 or undefined
  const maxGuests = selectedHall?.seating_capacity && selectedHall.seating_capacity > 15 
    ? selectedHall.seating_capacity 
    : 1000;

  // Effect to reset guest count when selected hall changes
  useEffect(() => {
    // Reset guest count if it exceeds the current hall's maximum capacity
    if (inputValue > maxGuests) {
      setInputValue(maxGuests);
      setGuests(maxGuests);
      toast.warning(`Guest count has been adjusted to the maximum allowed: ${maxGuests}`);
    }
  }, [selectedHall, maxGuests, inputValue, setGuests]);

  const handleSelectChange = (value: number) => {
    if (value <= maxGuests) {
      setInputValue(value);
      setGuests(value);
    } else {
      toast.error(`Maximum guest capacity is ${maxGuests}.`);
    }
  };
  
  const displayValue = inputValue === 0 ? "Add Guests" : `${inputValue} Guests`;

  return (
    <div className={`flex relative ${className}`}>
      <Popover className={`flex-1`}>
        {({ open }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none rounded-b-3xl ${
                open ? "shadow-lg" : ""
              }`}
            >
              <Popover.Button
                className={`relative z-10 flex-1 flex text-left items-center p-3 space-x-3 focus:outline-none`}
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold">
                    {displayValue}
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    Max Guests
                  </span>
                </div>
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl max-h-[40vh] overflow-y-auto">
                <div className="flex flex-col space-y-2">
                  {Array.from({ length: Math.ceil(maxGuests / 50) }, (_, i) => (i + 1) * 50)
                    .filter(num => num <= maxGuests)
                    .map(num => (
                      <button
                        key={num}
                        type="button"
                        className={`p-2 border border-gray-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-lg text-center ${
                          inputValue === num ? "bg-neutral-100 dark:bg-neutral-700" : "bg-white dark:bg-neutral-800"
                        }`}
                        onClick={() => handleSelectChange(num)}
                      >
                        {num} Guests
                      </button>
                    ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <Toaster richColors /> 
    </div>
  );
};

export default GuestsInput;
