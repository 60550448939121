import React, { FC, useState, useEffect } from "react";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import { useFirebase } from '../../context/firebase';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader"; // Import SyncLoader
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism"; // For background effect
import logoImg from "images/signup.png"; // Import the logo image for signup
import { Toaster, toast } from 'sonner'; // Import Toaster and toast

export interface PageSignUpProps {
  className?: string;
}

// Firebase error messages mapping
const getFriendlyErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case "auth/email-already-in-use":
      return "This email is already in use. Please use a different email or login.";
    case "auth/invalid-email":
      return "The email address is invalid. Please check your input.";
    case "auth/weak-password":
      return "Your password is too weak. Please use a stronger password.";
    case "auth/network-request-failed":
      return "Network error. Please check your connection.";
    default:
      return "An error occurred. Please try again.";
  }
};

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const firebase = useFirebase();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state

  useEffect(() => {
    const checkVerification = async () => {
      const user = firebase.getCurrentUser();
      if (user) {
        const verified = await firebase.checkEmailVerification(user);
        setIsVerified(verified);
        if (verified) {
          setShowVerificationPopup(false);
        }
      }
    };

    if (firebase.getCurrentUser() && !isVerified) {
      const intervalId = setInterval(checkVerification, 5000); // Check every 5 seconds
      return () => clearInterval(intervalId);
    }
  }, [firebase, isVerified]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await firebase.signupUserWithEmailAndPassword(formData.email, formData.password);
      setShowVerificationPopup(true);
      await firebase.authenticateGoogleCalendarIfVendor(); // Authenticate Google Calendar if vendor
      toast.success("Signup successful. Please verify your email.");
    } catch (error: any) {
      const friendlyMessage = getFriendlyErrorMessage(error.code); // Map error code to friendly message
      toast.error(friendlyMessage); // Show friendly error toast
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLoginWithGoogle = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      await firebase.signinWithGoogle();
      await firebase.authenticateGoogleCalendarIfVendor(); // Authenticate Google Calendar if vendor
      navigate('/');
      toast.success("Login successful.");
    } catch (error: any) {
      const friendlyMessage = getFriendlyErrorMessage(error.code); // Map error code to friendly message
      toast.error(friendlyMessage); // Show friendly error toast
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>tayari. | Sign up</title>
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
        <meta
          name="description"
          content="Create your Tayari account to start booking the best venues for your events. Sign up now and access personalized recommendations and exclusive offers."
        />
      </Helmet>


      {/* Toaster for displaying toast messages */}
      <Toaster richColors />

      {loading ? (
        // Show the SyncLoader while loading
        <div className="flex justify-center items-center min-h-screen">
          <SyncLoader color={"#ffd02b"} loading={loading} size={15} margin={2} />
        </div>
      ) : (
        <div className="min-h-screen flex justify-center items-center overflow-hidden px-4">

          {/* Set the signup box with responsive design */}
          <div className="w-full max-w-lg md:max-w-md lg:max-w-lg h-auto bg-white/30 dark:bg-neutral-900/30 backdrop-blur-md rounded-xl shadow-xl shadow-yellow-500/15 dark:shadow-yellow-500/15 p-8 md:p-12 lg:p-16">
            <BgGlassmorphism className="fixed inset-0 overflow-hidden -z-10 rounded-lg" />

            {/* Replace the text with the logo */}
            <div className="flex justify-start mb-8">
              <Link to="/"> {/* Wrap the image inside the Link component */}
                <img
                  src={logoImg}
                  alt="Sign up Logo"
                  className="w-32 md:w-40 h-auto" // Adjust width for responsiveness
                />
              </Link>
            </div>

            <div className="space-y-6">
              <div className="grid gap-3">
                <button
                  onClick={handleLoginWithGoogle}
                  className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                >
                  <img
                    className="flex-shrink-0"
                    src={googleSvg}
                    alt="Continue with Google"
                  />
                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                    Continue with Google
                  </h3>
                </button>
              </div>
              {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              {/* FORM */}
              <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Input
                    type="email"
                    name="email"
                    placeholder="example@example.com"
                    className="mt-1 rounded-lg"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                  </span>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Enter your Password"
                    className="mt-1 rounded-lg"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </label>
                <ButtonPrimary className="flex-grow text-center text-sm font-medium text-neutral-100 dark:text-neutral-300 sm:text-sm rounded-lg" type="submit">Signup</ButtonPrimary>
              </form>

              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                Already have an account? {` `}
                <Link to="/login" className="hover:underline text-blue-600 hover:text-blue-700 transition-all">Login</Link>
              </span>
            </div>
          </div>
        </div>
      )}

      {showVerificationPopup && !isVerified && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-neutral-800 p-6 rounded-lg shadow-lg relative">
            <h2 className="text-2xl font-semibold mb-4">Verify Your Email</h2>
            <p className="mb-4">Please check your email and verify your account.</p>
            <ButtonPrimary onClick={async () => {
              const user = firebase.getCurrentUser();
              if (user) {
                await firebase.sendVerificationEmail(user);
                console.log('Verification email sent');
              }
            }}>
              Resend Verification Email
            </ButtonPrimary>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSignUp;
