import React from "react";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'; // Import necessary styles for the skeleton

const CalendarSkeletonLoader = () => {
  return (
    <div className="calendar-skeleton-loader">
      <div className="lg:hidden">
        {/* For small screens */}
        <Skeleton height={800} />
      </div>
      <div className="hidden lg:block">
        {/* For larger screens */}
        <Skeleton height={400} />
      </div>
    </div>
  );
};

export default CalendarSkeletonLoader;

