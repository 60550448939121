import React, { FC, useEffect, useMemo, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { Place, TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import { useSearchContext } from "context/search";
import { useNavigate, Link } from "react-router-dom";
import useNcId from "hooks/useNcId";
import CardCategory3Skeleton from "components/CardCategory3/CardCategory3Skeleton";
import { useMediaQuery } from 'react-responsive';
import twFocusClass from "utils/twFocusClass"; // Import for focus utility

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Featured Vendors",
  subHeading = "Showcasing Our Top-Rated And Most Trusted Vendors",
  className = "",
  itemClassName = "",
  categoryCardType = "card3",
  itemPerRow = 5,
  sliderStyle = "style1",
  uniqueClassName,
}) => {
  const { featuredVenues, getFeaturedVenues } = useSearchContext();
  const navigate = useNavigate();
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + uniqueClassName + useNcId();
  const [venuesLoaded, setVenuesLoaded] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 640 });

  const MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: { perView: itemPerRow - 1 },
        1024: { gap: 20, perView: itemPerRow - 1 },
        768: { gap: 20, perView: itemPerRow - 2 },
        640: { gap: 20, perView: itemPerRow - 3 },
        500: { gap: 20, perView: 1.3 },
      },
    });
  }, [UNIQUE_CLASS, itemPerRow]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getFeaturedVenues();
        setVenuesLoaded(true);
        setTimeout(() => setIsDataReady(true));
      } catch (error) {
        console.error("Failed to fetch featured venues:", error);
      }
    };

    fetchData();
  }, [getFeaturedVenues]);

  useEffect(() => {
    if (venuesLoaded && isDataReady) {
      const timeoutId = setTimeout(() => {
        MY_GLIDEJS.mount();
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [MY_GLIDEJS, venuesLoaded, isDataReady]);

  const sortedVenues = useMemo(() => {
    return featuredVenues.sort((a, b) => {
      if (a.name === "RMK Farmhouse") return -1;
      if (b.name === "RMK Farmhouse") return 1;
      if (a.name === "EventionsEP") return -1;
      if (b.name === "EventionsEP") return 1;
      if (a.name === "The Millennium - Marquees & Events") return -1;
      if (b.name === "The Millennium - Marquees & Events") return 1;
      if (a.name === "The Atrium Marquee") return -1;
      if (b.name === "The Atrium Marquee") return 1;
      return 0;
    });
  }, [featuredVenues]);

  const renderSkeletons = () => {
    const skeletonCount = isSmallScreen ? 1 : 5;
    return Array.from({ length: skeletonCount }).map((_, index) => (
      <li key={index} className={`glide__slide ${itemClassName}`}>
        <CardCategory3Skeleton />
      </li>
    ));
  };

  const renderCard = (item: Place, index: number) => {
    const taxonomyItem: TaxonomyType = {
      id: item.id,
      href: `/listing-vendor/${item.name}/${item.type}/${item.id}`,
      name: item.name,
      taxonomy: "category",
      count: item.rating,
      halls: item.halls.length,
      thumbnail: item.images[0] || item.google_images[0],
    };

    const cardComponent = (() => {
      switch (categoryCardType) {
        case "card3":
          return <CardCategory3 taxonomy={taxonomyItem} />;
        case "card4":
          return <CardCategory4 taxonomy={taxonomyItem} />;
        case "card5":
          return <CardCategory5 taxonomy={taxonomyItem} />;
        default:
          return <CardCategory3 taxonomy={taxonomyItem} />;
      }
    })();

    return (
      <li key={index} className={`glide__slide ${itemClassName}`}>
        <Link to={`/listing-vendor/${item.name}/${item.type}/${item.id}`}>
          {cardComponent}
        </Link>
      </li>
    );
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <div className="flex justify-between items-center mb-4">
          {/* Heading Section */}
          <Heading
            desc={subHeading}
            isCenter={sliderStyle === "style2"}
          >
            {heading}
          </Heading>

          {/* Next/Prev buttons */}
          {sliderStyle === "style1" && (
            <div data-glide-el="controls" className="flex items-center">
              <button
                data-glide-dir="<"
                className={`w-10 h-10 lg:mt-0 mt-20 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-600 rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()} mr-[6px]`}
                title="Prev"
              >
                <i className="las la-angle-left"></i>
              </button>
              <button
                data-glide-dir=">"
                className={`w-10 h-10 lg:mt-0 mt-20 bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-600 rounded-full flex items-center justify-center hover:border-neutral-300 ${twFocusClass()}`}
                title="Next"
              >
                <i className="las la-angle-right"></i>
              </button>
            </div>
          )}
        </div>

        {!isDataReady ? (
          <ul className="grid grid-cols-1 gap-4 sm:grid-cols-4 lg:grid-cols-5">
            {renderSkeletons()}
          </ul>
        ) : (
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {sortedVenues.map((item, index) => renderCard(item, index))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
