import { Tab } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import CommonLayout from "./CommonLayout";
import StayCard from "components/StayCard/StayCard";
import { useFirebase } from "context/firebase";
import { useSearchContext } from "context/search";
import { Place } from 'data/types';
import { Helmet } from "react-helmet"; // Import Helmet

const AccountSavelists: React.FC = () => {
  const [categories] = useState(["Stays", "Experiences", "Cars"]);
  const { getCurrentUser } = useFirebase();
  const { allVenues, savedVenues, getSavedVenues } = useSearchContext();
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const fetchSavedVenues = async () => {
      const user = getCurrentUser();
      if (!user) {
        console.error("User not authenticated");
        setLoading(false); // Stop loading if user is not authenticated
        setIsLoggedIn(false); // Set isLoggedIn to false if user is not authenticated
        return;
      }

      setIsLoggedIn(true); // Set isLoggedIn to true if user is authenticated
      const userId = user.uid; 
      await getSavedVenues(userId);
    };

    fetchSavedVenues();
  }, []);

  const renderSection1 = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center py-10">
          <div className="loader">Loading...</div>
        </div>
      );
    }

    return (
      <div className="space-y-6 sm:space-y-8 min-h-screen">
        <div>
          <h2 className="text-3xl font-semibold">Saved Venues</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.Panels>
              <Tab.Panel className="mt-8">
                {isLoggedIn ? (
                  savedVenues.length > 0 ? (
                    <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                      {savedVenues.map((venue) => (
                        <StayCard key={venue.id} data={venue} />
                      ))}
                    </div>
                  ) : (
                    <div className="text-start text-gray-500">
                      Wishlist is empty
                    </div>
                  )
                ) : (
                  <div className="text-start text-gray-500">
                    Login to add items to wishlist
                  </div>
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>tayari. | Saved Venues</title>
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
        <meta 
          name="description" 
          content="Manage your saved venues on Tayari. Browse and organize your favorite places for your upcoming events." 
        />
      </Helmet>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
