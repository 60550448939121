import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSearchContext, SearchTab } from "context/search"; // Ensure SearchTab is imported correctly
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import SyncLoader from "react-spinners/SyncLoader";

export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({ className = "" }) => {
  const [searchParams] = useSearchParams();
  const { setLocation, setGuests, setDates, setSelectedType, searchPlaces, searchResults } = useSearchContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const location = searchParams.get("location") ?? "";
    const guestsParam = searchParams.get("guests");
    const startDateParam = searchParams.get("startDate");
    const type = (searchParams.get("selectedType") as SearchTab) || "Venues";

    if (location) {
      setLocation(location);

      if (guestsParam) {
        const guests = parseInt(guestsParam, 10);
        if (!isNaN(guests)) {
          setGuests(guests);
        }
      } else {
        setGuests(0); // Default to 0 if guests are not provided
      }

      if (startDateParam) {
        const startDate = new Date(startDateParam);
        if (startDate.toString() !== "Invalid Date") {
          setDates({ startDate, endDate: startDate });
        }
      } else {
        setDates({ startDate: null, endDate: null }); // No dates selected
      }

      setSelectedType(type);

      setLoading(false);
      searchPlaces().finally(() => setLoading(false));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!searchResults || searchResults.length === 0) {
      setLoading(false);
      searchPlaces().finally(() => setLoading(false));
    }
  }, [searchResults, searchPlaces]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        {/* <SyncLoader color={"#ffd02b"} loading={loading} size={15} margin={2} /> */}
      </div>
    );
  }

  return (
    <div className={`nc-ListingStayMapPage relative ${className}`} data-nc-id="ListingStayMapPage">
      <Helmet>
        <title>tayari. | Search Results</title>
        <meta
          name="description"
          content="Browse the search results on Tayari and discover the perfect venues that match your event needs. Explore a wide range of options for your next event."
        />
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
      </Helmet>

      <BgGlassmorphism />
      <div className="container pt-10 lg:pt-16 ">
        <SectionHeroArchivePage currentPage="Venues" currentTab="Venues" />
      </div>
      <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
        <SectionGridHasMap />
      </div>
    </div>
  );
};

export default ListingStayMapPage;
