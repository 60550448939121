import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { BACKEND_URL } from "backendUrl";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Toaster, toast } from 'sonner'; // Import Toaster and toast from sonner
import axios from "axios"; // Import Axios for HTTP requests
import logoImg from "images/contact.png"; // Import the logo image
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "💌 EMAIL",
    desc: "info@tayaripk.com",
    icon: "las la-envelope",
  },
  {
    title: "☎ PHONE",
    desc: "+92 304 3329988",
    icon: "las la-phone",
  },
  {
    title: "📲 Whatsapp",
    desc: "+92 304 3329988",
    icon: "las la-message",
  },
  {
    title: "📍 ADDRESS",
    desc: "Top City 1, Islamabad, Pakistan",
    icon: "las la-map-marker-alt",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  // Define state variables for form fields
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      // Send a POST request to the backend
      await axios.post(`${BACKEND_URL}contact`, {
        fullName,
        email,
        message,
      });

      // Notify success
      toast.success("Message sent successfully!");

      // Reset form fields
      setFullName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Please try again later.");
    }
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>tayari. | Contact Us</title>
        <meta name="robots" content="noindex" /> {/* Added noindex tag here */}
        <meta
          name="description"
          content="Get in touch with Tayari for inquiries, support, and venue booking assistance. We're here to help you find the perfect venue for your event."
        />
      </Helmet>

      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex justify-center">

            {/* Box enclosing the entire content */}
            <div className="p-8 bg-white dark:bg-neutral-900 rounded-lg shadow-lg shadow-yellow-100 w-full max-w-5xl">
              {/* Logo Image */}
              <div className="flex justify-center mb-8">
                <img
                  src={logoImg}
                  alt="Logo"
                  className="w-32 md:w-28 h-auto max-w-[240px]" // Added max-width for finer control
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-12">
                <div className="max-w-sm space-y-8">
                  {info.map((item, index) => (
                    <div key={index} className="flex items-center space-x-3">
                      <div>
                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                          {item.title}
                        </h3>
                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                          {item.desc}
                        </span>
                      </div>
                    </div>
                  ))}
                  <div>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      🌏 SOCIALS
                    </h3>
                    <SocialsList className="mt-2" />
                  </div>
                </div>
                <div>
                  <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                    <label className="block">
                      <Label>Full name</Label>
                      <Input
                        placeholder="Example Doe"
                        type="text"
                        className="mt-1"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                      />
                    </label>
                    <label className="block">
                      <Label>Email address</Label>
                      <Input
                        type="email"
                        placeholder="example@example.com"
                        className="mt-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </label>
                    <label className="block">
                      <Label>Message</Label>
                      <Textarea
                        className="mt-1"
                        rows={6}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      />
                    </label>
                    <div>
                      <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster richColors />
    </div>
  );
};

export default PageContact;
