import React, { FC, ReactNode } from "react";
import imagePng from "images/hero-right.png";
import HeroSearchForm, { SearchTab } from "components/HeroSearchForm/HeroSearchForm";
import { useSearchContext } from "context/search";
import { MdOutlineCelebration } from "react-icons/md";

// Images for specific cities
import islamabadImage from "images/islamabad.webp"; // Replace with actual image paths
import lahoreImage from "images/lahore.webp";
import KarachiImage from "images/Karachi.webp";

import defaultImage from "images/hero-right2.png";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Venues" | "Caterers" | "Decorators" | "Photographers";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage,
}) => {
  const { city, country, searchResults, selectedType } = useSearchContext();

  // Map cities to images
  const cityImages: { [key: string]: string } = {
    Islamabad: islamabadImage,
    Lahore: lahoreImage,
    Karachi: KarachiImage,
  };

  // Map cities to detailed descriptions
  const cityDescriptions: { [key: string]: JSX.Element } = {
    Islamabad: (
      <>
        <p className="mb-2">
          Islamabad, the capital city of Pakistan, is known for its modern architecture,
          lush greenery, and scenic beauty. <br /> Key features include:
        </p>
        <ul className="list-disc pl-2">
          <li>Faisal Mosque: The largest mosque in Pakistan, known for its contemporary design.</li>
          <li>Pakistan Monument: A symbol of national unity.</li>
          <li>Daman-e-Koh: A popular viewpoint in the Margalla Hills.</li>
          <li>Islamabad is also a center for political activities and international diplomacy.</li>
        </ul>
      </>
    ),
    Lahore: (
      <>
        <p className="mb-2">
          Lahore, the cultural capital of Pakistan, is a vibrant city known for its rich history,
          arts, and culinary delights. <br /> Highlights include:
        </p>
        <ul className="list-disc pl-2">
          <li>Lahore Fort and Shalimar Gardens: UNESCO World Heritage Sites.</li>
          <li>Badshahi Mosque: One of the largest mosques in the world.</li>
          <li>Anarkali Bazaar: A bustling market with a rich history.</li>
          <li>Lahore is famous for its food, festivals, and warm hospitality, making it a must-visit destination.</li>
        </ul>
      </>
    ),
    Karachi: (
<>
  <p className="mb-2">
    Karachi, the bustling metropolis of Pakistan, is a diverse city known for its blend of modernity
    and history. <br /> Highlights include:
  </p>
  <ul className="list-disc pl-2">
    <li>Clifton Beach: A popular seaside spot offering stunning sunsets and a lively atmosphere.</li>
    <li>Mazar-e-Quaid: The mausoleum of Quaid-e-Azam Muhammad Ali Jinnah, an iconic symbol of the city.</li>
    <li>Mohatta Palace: A beautiful museum that showcases Karachi's architectural heritage and art.</li>
    <li>Port Grand: A vibrant waterfront destination with dining, entertainment, and scenic views of the harbor.</li>
    <li>Karachi's diverse culture, coastal charm, and vibrant energy make it a must-visit destination.</li>
  </ul>
</>

    ),
    // Add more detailed descriptions as needed
  };

  // Use the city image if available, otherwise fall back to default image
  const imageSrc = cityImages[city] || imagePng;

  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
            {city}, {country}
          </h2>
          <div className="flex items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            <i className="text-2xl las la-map-marked"></i>
            <span className="ml-2.5">{country}</span>
            <span className="mx-5"></span>
            {listingType ? (
              listingType
            ) : (
              <>
                <MdOutlineCelebration size={25} />
                {/* Remove the "s" from selectedType if there's only one result */}
                <span className="ml-2.5">
                  {searchResults.length}{" "}
                  {searchResults.length === 1 ? selectedType.slice(0, -1) : selectedType}
                </span>
              </>
            )}
          </div>

          <div className="text-sm md:text-base text-neutral-500 dark:text-neutral-400">
            {cityDescriptions[city] || "Description not available."}
          </div>
        </div>
        <div className="">
          <img className="w-full mb-32" src={rightImage || imageSrc} alt="hero" />
        </div>
      </div>

      {/* <div className="hidden lg:flow-root w-full">
        <div className="z-10 lg:-mt-60 xl:-mt-60 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} className="" />
        </div>
      </div> */}
    </div>
  );
};

export default SectionHeroArchivePage;
