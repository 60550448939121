import React from "react";
import MyRouter from "routers/index";
import { HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <HelmetProvider>
      <MyRouter />
      </HelmetProvider>
    </div>
  );
}

export default App;
