import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import { useSearchContext } from "context/search";
import { useNavigate } from "react-router-dom";
import { Place } from 'data/types';

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  closeModal?: () => void; // Add closeModal as a prop
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText = "Search Location or Vendor",
  closeModal = () => {}, // Default empty function
}) => {
  const { location, setLocation, allVenues, setSelectedVenue } = useSearchContext();
  const [value, setValue] = useState(defaultValue);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [filteredVenues, setFilteredVenues] = useState<Place[]>([]);
  const suggestions = [
    "Islamabad, Pakistan",
    "Lahore, Pakistan",
    "Karachi, Pakistan",
  ];
  
  // Sample popular vendors
  const popularVendors = allVenues.slice(1, 4); // Take 4 popular vendors from the venue list

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setValue(location);
    setFilteredSuggestions(suggestions);
  }, [location]);

  const handleSelectLocation = (address: string) => {
    setLocation(address);
    setValue(address);
    setShowSuggestions(false);
  };

  const handleSelectVenue = (venue: Place) => {
    setSelectedVenue(venue);
    closeModal(); // Close the modal when navigating
    navigate(`/listing-vendor/${venue.name}/${venue.type}/${venue.id}`);
    setShowSuggestions(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    setValue(inputValue);
    setShowSuggestions(true);

    // Filter locations
    const filteredLocs = suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filteredLocs);

    // Filter venues by name
    const filteredVens = allVenues.filter((venue) =>
      venue.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredVenues(filteredVens);
  };

  // Handle clearing the location and input when clicked if a location is already selected
  const handleInputFocus = () => {
    if (location) {
      setLocation(""); // Clear the selected location
      setValue(""); // Clear the input value as well
    }
  };

  const renderSearchValues = () => {
    // If input is empty, show both popular locations and popular vendors
    if (value.trim() === "") {
      return (
        <>
          <p className="block font-semibold text-base mt-5">
            Popular Locations
          </p>
          <div className="mt-3">
            {suggestions.map((item) => (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
                onClick={() => handleSelectLocation(item)}
                key={item}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item}</span>
              </div>
            ))}
          </div>
          <p className="block font-semibold text-base mt-5">
            Popular Vendors
          </p>
          <div className="mt-3">
            {popularVendors.map((vendor) => (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
                onClick={() => handleSelectVenue(vendor)}
                key={vendor.id}
              >
                <MagnifyingGlassIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span>{vendor.name}</span>
              </div>
            ))}
          </div>
        </>
      );
    }

    // If input is not empty, show filtered locations and vendors
    return (
      <>
        {filteredSuggestions.length > 0 && (
          <>
            <p className="block font-semibold text-base mt-5">
              Locations
            </p>
            <div className="mt-3">
              {filteredSuggestions.map((item) => (
                <div
                  className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
                  onClick={() => handleSelectLocation(item)}
                  key={item}
                >
                  <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                  <span className="">{item}</span>
                </div>
              ))}
            </div>
          </>
        )}
        {filteredVenues.length > 0 && (
          <>
            <p className="block font-semibold text-base mt-5">
              Vendors
            </p>
            <div className="mt-3">
              {filteredVenues.map((venue) => (
                <div
                  className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
                  onClick={() => handleSelectVenue(venue)}
                  key={venue.id}
                >
                  <MagnifyingGlassIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                  <span>{venue.name}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Select Location or Vendor"}
            value={value || ""} // Ensure the value is a string, even if it's null
            onChange={handleInputChange}
            onFocus={handleInputFocus} // Clear the location on focus if a location is selected
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7 max-h-[400px] overflow-y-auto"> {/* Fixed height with scroll */}
          {showSuggestions ? (
            renderSearchValues() // Render Locations and Vendors with separate headings
          ) : (
            <>
              {renderSearchValues()} {/* Show Popular Locations */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
